<template>
  <div class="pb" v-if="strainData">

    <div class="headerow"> 

      <h1>
        {{ $t('grow_strains_view_title') }}      
      </h1>  
 
      <GeneralSearchShort
        @search="changeKeyword"
        :val="keyword"
        placeholder="Search strains"
        />   
        
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'az_desc', name: $t('universal_sort_az'), icon: 'desc'},
          {id:'az_asc', name: $t('universal_sort_az'), icon: 'asc'},
          {id:'rate_desc', name: $t('universal_sort_rating'), icon: 'desc'},
          {id:'rate_asc', name: $t('universal_sort_rating'), icon: 'asc'},
          {id:'gplant_desc', name: $t('universal_sort_gplant'), icon: 'desc'},
          {id:'gplant_asc', name: $t('universal_sort_gplant'), icon: 'asc'},
          {id:'harvests_desc', name: $t('universal_sort_harvests'), icon: 'desc'},
          {id:'harvests_asc', name: $t('universal_sort_harvests'), icon: 'asc'},
          {id:'diaries_desc', name: $t('universal_sort_diaries'), icon: 'desc'},
          {id:'diaries_asc', name: $t('universal_sort_diaries'), icon: 'asc'},
        ]"
        />


      <GeneralSearchTags 
        :tags="tags" 
        :tagsselected="tagsSelected" 
        :tagsdefault="tags" 
        :tagscount="strainData?.items_tag"    
        :modify-path="true"
        :tag-link="'/strains'"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        @clear="clearTags"
        />

    </div>
 

    <SkStrainsRows
      v-if="strainDataStatus === 'pending' && start == 0"
      />

    <div v-else-if="strainData.items?.length" class="products products_boxs two_column">

      <GeneralSeedRow
        v-for="(item, num) in strainData.items"        
        :rating="item.item_stat.rate"
        :logo="item.cover_s"
        :link="item.link"
        :nm="item.name"
        :country="item.country"
        :item_stat="item.item_stat"
        :diaries="item.item_stat.cnt_reports"
        :growers="item.item_stat.cnt_growers"
        :harvests="item.item_stat.cnt_harvests"
        :rate="item.item_stat.avg_rate"
        :types="item.items_type"
        :item_brand="item.item_brand"
        :autof="item.autof"
      />


    </div>

    <UiEmpty
      v-else
      />

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="strainData?.items?.length >= limit"
        :is-loading="strainDataStatus === 'pending'"
        ref="elInfinityScroll"/>
    </ClientOnly>

  </div>
</template>

<script setup lang="ts"> 

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

import { TagsStrains, getTagsKeys, getTagsSimple } from '@/types/other'

const route = useRoute();
const isEnded = ref(false)
const {$api, $adv, $ga, $patcher} = useNuxtApp()
const elInfinityScroll = ref(null)
const tags = ref(structuredClone(TagsStrains))


const props = defineProps({
  inurl: {
    type: String,
    required: false
  },
})

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('harvests_desc');
const tagscount = ref([]);
const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tagsDefault = ref(null);
const list = ref([]);
const keyword = ref(route.query['keyword']?.length ? route.query['keyword'] : '')
const tagsPermanent = ref([]);
 
tagsPermanent.value.push('not_tests');
tagsPermanent.value.push('cg:seed');

const clearPagination = function(){
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsSelected.value = tagsSelected.value.filter((item) => item != selected);
}     
   
const clearTags = function(){          
  tagsSelected.value = [];
  $ga.eventGA('explore', 'tag', getTagsSimple(tags.value, tagsSelected.value));
}

const chooseTags = function(selected){          
  tagsSelected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(tags.value, tagsSelected.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}
 
const changeKeyword = function(text) {  
  start.value = 0;
  keyword.value = text;
}  
 

const loadData = async function() {  
  const response = await $api.getProducts({
    start: start.value,
    limit: limit.value,
    keyword: keyword.value,
    sortable: sort.value,
    tagsall: getTagsKeys(tags.value), 
    tags: getTagsSimple(tags.value, tagsSelected.value),
    tagsper: getTagsSimple(tags.value, tagsPermanent.value)
  });
  return response;  
}



const { status: strainDataStatus,  data: strainData } = await useLazyAsyncData('strainData', async () => await loadData())


watchArray([start, sort, tagsSelected, keyword], async ([new_start, new_sort, new_tagsSelected, new_keyword], added, removed) => {
  

  $patcher.change('/seeds', {
    tags: new_tagsSelected,
    keyword: new_keyword,
    sort: new_sort,
  });

  
  console.log('watcher');
  strainDataStatus.value = 'pending'
  var dt = await loadData();


  if(new_start == 0){
    strainData.value.items = [];
    isEnded.value = false;
  }
    
  if(dt.length == 0)
    isEnded.value = true;  

  strainData.value.items = [...strainData.value.items, ...dt.items]
  if(new_start == 0)
    strainData.value.items_tag = dt.items_tag

  strainDataStatus.value = 'success'

  $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(strainDataStatus.value === 'success' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


useHead(useNuxtApp().$head.getSeeds());


 
</script>

<style scoped>

.products{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}
h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
  .products{
    margin-top: 0rem;
  }
}
 
</style>
